/* TollPlazaMap.css */

#map {
    width: 100%;
    height: 85vh;
    /* padding-top: 50px; */
    margin-top: 10px;
}

@media (max-width: 768px) {
    #map {
        height: 380px;
        width: 95%;
        /* margin:auto; */
    
    }
}
