#map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;  /* Full viewport height */
  }
  
  #map {
    height: 85vh;  /* Adjust map height */
    width: 100%;
  
    z-index: 1;  /* Ensure map is above other content */
  }

.custom-marker {
    background: white;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
  }
  
  .direction-step {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .direction-number {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .direction-text {
    font-size: 14px;
  }
  /* Hide the directions sidebar or popup */
.directions-container {
    display: none; /* Hides the directions container */
  }
  
  .back-button {
    padding: 10px 20px;

    background-color: #007bff; /* Blue color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .back-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .location-history-page {
    margin-top: 50px;
    padding: 10px;
  }
