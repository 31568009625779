:root{

    /* Colors */
    --color-primary: #5E81F4;
    --color-text: #545d7a;
    --color-white: #fff;
    --color-title: #242e4c;
    --color-background: #f7f8fb;
    --color-border: #dae1f5;
    
    /* Fonts */
    --font-family: "Satoshi", sans-serif;
    --fs-sm: 1.4rem;
    --fs-md: 1.1rem;
    --fs-lg: 1.5rem;
    --fw-regular: 400;
    --fw-medium: 500;
    --fw-bold: 700;
    
    /* Transition & Box Shadow */
    --transition: 0.4s ease-in-out; 
    --shadow: 0px 60px 56px -12px rgba(9, 40, 163, 0.05); 
    
    }
    
    html {
        /* font-size: 62.5%; */
    }
   
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /* box-sizing: content-box; */
    }
    
    /* body {
        background-color: var(--color-background);
        font-family: var(--font-family);
        color: var(--color-text);
        font-size: var(--fs-md);
        font-weight: var(--fw-regular);
    } */
    
    .container{ 
        width: 100%;
        margin: auto;
        min-height: 100vh;
        padding: 0.5rem;
        display: grid;
        place-items: center;
    }
    
    .accordion__wrapper {
        background-color: #fff;
        box-shadow: var(--shadow);
        border-radius: 2.5rem;
        width: 100%;
        max-width: 60rem;
        /* padding: 5rem; */
    }
    
    .accordion__title{
        font-size: 1.7rem;
        font-weight: 800;
        color: #242e4c;
        text-align: center;
        margin-bottom: 2rem;
    }
    
    .accordion {
        border-bottom: 0.1rem solid #dae1f5;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }
    
    .accordion__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.8rem;
        cursor: pointer;
    } 
    
    .accordion__icon {
        background-color: var(--color-primary);
        width: 2.2rem;
        height: 2.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: var(--color-white);
        flex-shrink: 0;
    }
    
    .accordion__question{
        font-size: var(--fs-md);
        font-weight: var(--fw-medium);
        color: var(--color-title);
    }
    
    .accordion__answer{
        padding: 1rem 0;
    }
    
    .accordion__content{
        overflow: hidden;
        height: 0;
        transition: var(--transition);
    }
    
    @media screen and (min-width: 580px) {
        .accordion__wrapper {
            /* padding: 5rem 8rem; */
            padding: 40px 20px ;
        }
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    