/* VehicleDataTable.css */

.vehicle-container {
    /* max-width: 1200px; */
    margin: 0 auto;
    padding: 20px;
 
  
}

.vehicle-heading {
    text-align: center;
    color: #5E81F4;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Action section with Search and Add Vehicle functionality */
.action-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.search-section, .add-vehicle-section {
    display: flex;
    align-items: center;
}

.search-input, .add-input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 10px;
    width: 250px;
}

.search-btn, .add-btn {
    padding: 10px 20px;
    background-color: #5E81F4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.search-btn:hover, .add-btn:hover {
    background-color: #4A6FCC;
}

/* Vehicle Table */
.vehicle-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.vehicle-table th, .vehicle-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.vehicle-table th {
    background-color: #5E81F4;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
}

.vehicle-table td {
    color: #333;
}

.vehicle-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.vehicle-table tr:hover {
    background-color: #e0e7ff;
    cursor: pointer;
}

/* No data message */
.no-data-text {
    text-align: center;
    color: #888;
    font-size: 1.2rem;
}

.expired {
    color: red !important; /* Enforce red font color */
}



.no-data-text {
    color: #888;
}