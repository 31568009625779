@tailwind base;
@tailwind components;
@tailwind utilities;


*{
    /* border: 1px solid green; */
}

.gradient-border{
    border-image-source: linear-gradient(90deg,80% #444CE7,20% #ffffff);
}
body{
    background-color: #F2F2F2;

}

::-webkit-scrollbar {
    width: 2px; /* Width of vertical scrollbar */
    /* height: 12px;  */
}